import React from 'react'
import Navbar from './Navbar'
import Footer1 from './Footer1'

export default function Mycard() {
  return (
    <div>
        <Navbar/>

        <Footer1/>
    </div>
  )
}
