import React from 'react'
import {FiEdit} from 'react-icons/fi'

import '../css/Edit.css'

export default function Edit() {
  return (
    <div><FiEdit/>
    </div>
  )
}
