import React from 'react'
import "../css/loader.css"

export default function loader() {
  return (
    <div>
        <div className="loader">  <div className="book">
    <div className="book__pg-shadow"></div>
    <div className="book__pg"></div>
    <div className="book__pg book__pg--2"></div>
    <div className="book__pg book__pg--3"></div>
    <div className="book__pg book__pg--4"></div>
    <div className="book__pg book__pg--5"></div>
  </div></div>
    </div>
  )
}
