import React from 'react';
import Navbar from './Navbar';
import Footer1 from './Footer1';
import '../css/Partner.css'; // Имя файла может быть любым, например, Partner.css

export default function Partner() {
    return (
        <div>
            <Navbar />
            <div className="asd">
                <div className="asd-block">
                    <h1 className="partner-header">Our Partners</h1>
                    <br />
                    <div className="mini"></div>
                </div>
            </div>
            <div className="sahifa">
                <div className="sahifa-glav">
                    <div className="safiha-p">
                        <h4><a href="/">Home  <span><box-icon name='chevron-right' color='#6b3d0c' ></box-icon></span></a></h4>
                    </div>
                    <div className="safiha-d">
                        <p id='tepaga'>Our Partners</p>
                    </div>
                </div>
            </div>

            <div className="part_cards">
                <div className="card">
                    <img src="https://baisan.org/impart/2.jpeg" alt="Partner 1" />
                    <h3 className="partner-title">RAFFLES UNIVERSITY</h3>
                    <p className="partner-description"> is the university of the future. We are at the cutting edge of higher education in both teaching and learning, and business practices. Our innovative and stimulating programmes promote doing while learning and learning while doing.</p>
                </div>

                <div className="card">
                    <img src="https://baisan.org/impart/9.jpeg" alt="Partner 2" />
                    <h3 className="partner-title">ANGLO-AMERICAN UNIVERCITY</h3>
                    <p className="partner-description"> is an innovative educational institution geared towards the future. We are at the forefront of higher education, combining high-quality teaching with modern methods and practical experience. AAU's programs foster active learning, allowing students to acquire knowledge while mastering practical skills.</p>
                </div>

                <div className="card">
                    <img src="https://baisan.org/impart/6.jpeg" alt="Partner 3" />
                    <h3 className="partner-title">UNITEN</h3>
                    <p className="partner-description"> is a dynamic educational institution committed to advancing knowledge and fostering innovation. Renowned for its cutting-edge research and progressive teaching methodologies, UNITEN stands at the forefront of higher education. Our programs emphasize experiential learning, encouraging students to apply theoretical knowledge in real-world situations. As a hub for academic excellence, UNITEN is dedicated to shaping future leaders and contributing to the global intellectual landscape.</p>
                </div>

                <div className="card">
                    <img src="https://baisan.org/impart/3.jpeg" alt="Partner 4" />
                    <h3 className="partner-title">Wekerle Business School</h3>
                    <p className="partner-description"> is a prominent institution dedicated to providing high-quality business education. With a focus on excellence in teaching and innovative learning methods, the school aims to prepare students for success in the dynamic field of business. The programs offered at Wekerle Business School emphasize practical skills and real-world applications, ensuring that graduates are well-equipped for the challenges of the business world. The school's commitment to academic rigor and a supportive learning environment makes it a preferred choice for those aspiring to excel in the business domain.</p>
                </div>

                <div className="card">
                    <img src="https://baisan.org/impart/1.jpeg" alt="Partner 5" />
                    <h3 className="partner-title">Nilai University</h3>
                    <p className="partner-description"> is a distinguished institution committed to delivering top-tier education in Nilai, Negeri Sembilan, Malaysia. With a steadfast dedication to academic excellence and innovative teaching approaches, the university strives to equip students for success in various fields. The array of programs available at Nilai University spans diverse disciplines, including business, engineering, hospitality, health sciences, and more.

The university boasts an international outlook, attracting a global student body that contributes to a rich and multicultural learning environment. Nilai University places a strong emphasis on practical learning experiences, incorporating internships, industry attachments, and hands-on training to ensure graduates are well-prepared for the demands of their respective industries.

Situated in Nilai, the campus is equipped with state-of-the-art facilities, including lecture halls, laboratories, libraries, and recreational spaces. This infrastructure supports both academic endeavors and extracurricular activities, fostering a holistic educational experience.

Engaged in research and innovation, Nilai University is at the forefront of advancing knowledge across various domains. The institution also actively participates in community initiatives, reflecting its commitment to societal development.

For those seeking a university that combines academic rigor with a supportive learning environment, Nilai University stands out as a preferred choice. The university's dedication to excellence positions it as an ideal institution for individuals aspiring to excel in their chosen fields of study.</p>
                </div>

                <div className="card">
                    <img src="https://baisan.org/impart/10.jpeg" alt="Partner 6" />
                    <h3 className="partner-title">UCSI University</h3>
                    <p className="partner-description"> is a leading educational institution in Kuala Lumpur, Malaysia. Renowned for its diverse academic offerings in business, engineering, health sciences, liberal arts, and information technology, UCSI attracts students globally, creating a vibrant, multicultural learning environment. The university's commitment to industry relevance is evident through practical training, internships, and collaborations, ensuring graduates are well-prepared for professional success.

Situated in the heart of Kuala Lumpur, UCSI's campus features modern facilities supporting academic and extracurricular activities. The university actively contributes to research and innovation, fostering advancements in various fields. Engaged in community outreach, UCSI demonstrates a dedication to societal development.

For individuals seeking a dynamic and globally recognized university with a focus on practical education, UCSI University stands as an excellent choice.</p>
                </div>

                <div className="card">
                    <img src="https://baisan.org/impart/7.jpeg" alt="Partner 7" />
                    <h3 className="partner-title">IBS (International Business School)</h3>
                    <p className="partner-description">  is a highly sought-after educational institution offering a diverse range of higher education programs with a focus on business. Located in Budapest, Hungary, the school is known for its international approach to education and a unique learning environment. IBS programs cover key areas such as management, marketing, finance, and international business.</p>
                </div>

                <div className="card">
                    <img src="https://baisan.org/impart/8.jpeg" alt="Partner 8" />
                    <h3 className="partner-title">The International Institute in Geneva (IIG)</h3>
                    <p className="partner-description"> is an institution that offers a range of programs and courses. Here's a concise overview:

IIG provides diverse educational programs, fostering a global perspective in the heart of Geneva, Switzerland. Offering courses in various fields, including international relations, diplomacy, and business, IIG aims to equip students with the knowledge and skills needed for success in an increasingly interconnected world.</p>
                </div>

                <div className="card">
                    <img src="https://baisan.org/impart/4.jpeg" alt="Partner 9" />
                    <h3 className="partner-title">The Geneva School of Diplomacy and International Relations (GSD)</h3>
                    <p className="partner-description"> is dedicated to providing education in the fields of diplomacy and international relations. Situated in the diplomatic hub of Geneva, the school offers programs designed to equip students with the knowledge and skills necessary for careers in international affairs. GSD emphasizes a global perspective, preparing students to navigate the complexities of diplomacy and contribute to the international community.

For comprehensive and up-to-date information about the programs, admissions, and other offerings, it is recommended to explore the official website of the Geneva School of Diplomacy and International Relations or contact the institution directly.</p>
                </div>
                <div className="card">
                    <img src="https://baisan.org/impart/5.jpeg" alt="Partner 10" />
                    <h3 className="partner-title">Al-Madinah International University (MEDIU)</h3>
                    <p className="partner-description"> is a private institution located in Shah Alam, Selangor, Malaysia. The university offers diverse academic programs at the undergraduate and postgraduate levels, with a strong emphasis on Islamic studies. Known for its online learning platforms, MEDIU provides distance education opportunities globally. The university is actively involved in research and publications, contributing to knowledge in Islamic studies and other fields. With a diverse student body, including international students, MEDIU provides an education with a focus on Islamic principles. For the latest and detailed information, it is recommended to visit the official website of Al-Madinah International University.</p>
                </div>



                {/* Добавьте дополнительных партнеров по аналогии */}
            </div>

            <Footer1 />
        </div>
    );
}
