import React from 'react'
import {MdDeleteOutline} from 'react-icons/md'
import '../css/Delete.css'

export default function Delete() {
  return (
    <div>
        <MdDeleteOutline/>
    </div>
  )
}
