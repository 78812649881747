import React from 'react';
import '../css/accordion1.css'


export default function About_comment() {
    return(
    <div>
        <h5>Содержание курса</h5>
       
        </div>
        )
    }